@media only screen and (max-width: 768px) {
  .MainContainer {
    height: 100% !important;
  }
  .VerificationText {
    font-size: 1rem !important;
  }
  .WrapperCenter {
    height: 100% !important;
  }
  .WrapperCenterWrapper {
    background-color: rgba(0, 0, 0, 0) !important;
    padding: 0 !important;
  }
  .HeroGifWrapper {
    margin-right: 0px !important; 
  }
}

@media only screen and (max-width: 475px) {
  .VerificationText {
    font-size: 0.7rem !important;
  }
  .HeroGif {
    height: 220px !important;
    width: 220px !important;
  }
  .verifiedImg {
    width: 200px !important;
  }
}

@media only screen and (max-width: 375px) {
  .VerificationText {
    font-size: 0.5rem !important;
  }
}
